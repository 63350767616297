<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable persistent>
    <v-card elevation="0" tile>
      <v-card-title class="pa-1" v-bind:style="{
        background: this.$vuetify.breakpoint.smAndDown ? '#FF6969' : '#2a5298'
      }">
        <v-row no-gutters>
          <v-col>
            <v-btn class="pa-0 ma-0 text-white" text @click="cancelDialog"
              :color="this.$vuetify.breakpoint.smAndDown? 'white' : 'warning'">
              <v-icon size="40px">mdi-chevron-left</v-icon>
              <span v-if="!this.$vuetify.breakpoint.smAndDown">{{$t('generic.lang_prev')}}</span>
            </v-btn>
          </v-col>
          <v-col align="center">
            <p class="text-center text-white ma-0" style="width:100%;">{{ $t('generic.lang_startShift') }}</p>
          </v-col>
          <v-col align="right">
            <v-btn class="pa-0 ma-0 text-white" text>
              <span>{{ user.name }} {{ user.last_name }}</span>
              <v-icon size="20px" color="white">mdi-lock</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider class="pa-0 ma-0" />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="7">
              <v-row>
                <v-col v-if="countingMandatory && !validCountingInput" cols="12">
                  <v-alert type="warning" outlined dense class="mb-0">
                    {{ $t('accounting.lang_countingCashMandatory') }}
                  </v-alert>
                </v-col>
                <v-col v-show="!$vuetify.breakpoint.smAndDown" cols="4" sm="6" md="6" lg="4" xl="4"
                  v-for="(coin) in currency[currencyIMG].muenzen" :key="coin.value">
                  <v-row no-gutters align="end">
                    <v-col cols="6" align="end" align-self="center" class="pr-1">
                      <v-img :src="coin.image" width="50%" contain />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field min="0" v-model="coin.inValue" height="50px" type="number"
                        @focus="remember($event,coin)" clearable :disabled="!usingCounting" @input="update(null)">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-show="!$vuetify.breakpoint.smAndDown" cols="4" sm="6" md="6" lg="4" xl="4"
                  v-for="(paper)  in currency[currencyIMG].scheine" :key="paper.value">
                  <v-row no-gutters>
                    <v-col class="pr-1" cols="6" align="end" align-self="center">
                      <v-img :src="paper.image" width="100%" contain />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field min="0" @focus="remember($event,paper)" v-model="paper.inValue" height="50px"
                        type="number" clearable :disabled="!usingCounting" @input="update(null)">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="5">
              <v-card elevation="0" height="70%" width="100%">

                <v-card-title class="pa-0">
                  <v-row>
                    <v-col cols="12" align="right" class="text-right">
                      <v-switch :label="$t('generic.lang_countCash')" v-model="usingCounting"
                        :disabled="countingMandatory">
                      </v-switch>
                    </v-col>
                    <v-col cols="12" class="pb-4">
                      <v-text-field :label="$t('generic.lang_name')" required v-model="name" @blur="checkShiftName"
                        @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" outlined
                        :placeholder="$t('generic.lang_enterNameForTheShift')+'...'" hide-details>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-currency-field :local="$store.getters['settings/getSettingValue']('geoloc_systemcountry')"
                        :label="$t('erp.lang_DailyAnfangsbestand')" outlined required @focus="remember($event,null)"
                        v-model="countedStartCash" append-icon="book" :disabled="loadingCashbookValue"
                        :loading="loadingCashbookValue" @keydown="checkNegativity($event)"
                        @click:append="getCashFromCashBook" :readonly="usingCounting"></v-currency-field>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text class="pa-0 ma-0">

                  <CashoutNumpad @change="update" @del="del" :button-style="{height:60,padding:7}" double-zero />
                </v-card-text>
                <v-row align="end">
                  <v-col large cols="12" align="end">
                    <v-spacer v-if="user.userID !== 101 && !$vuetify.breakpoint.smAndDown"></v-spacer>
                    <v-btn v-bind:class="this.$vuetify.breakpoint.smAndDown? 'text-white mx-0' : ''"
                      :elevation="this.$vuetify.breakpoint.smAndDown? 0 : 2" :block="this.$vuetify.breakpoint.smAndDown"
                      :large="this.$vuetify.breakpoint.smAndDown"
                      :color="this.$vuetify.breakpoint.smAndDown? '#FF6969' : 'success'" v-if="user.userID !== 101"
                      @click="createShift" :disabled="name.length === 0 || loading || !validCountingInput" :loading="loading">
                      {{ $t('generic.lang_startShift') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>
    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard" :accept="hideTouchKeyboard" :input="touchKeyboard.input" />
    </div>
    <!-- SHIFT ERROR DIALOG -->
    <v-dialog max-width="300" v-model="showShiftErrorDialog" persistent>
      <v-card>
        <v-card-title>{{ $t('generic.lang_error') }}</v-card-title>
        <v-card-text class="text-center">{{ $t('generic.lang_thereIsNetwork_internetProblem') }}</v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="$router.replace({name: 'dashboard'})">{{ $t('generic.lang_prev') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="refreshPage">{{ $t('generic.lang_refreshPage') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ERROR DIALOG -->
    <v-dialog :value="errorDialogMessage.length > 0" persistent max-width="400">
      <v-card>
        <v-card-title>{{ $t('generic.lang_info') }}</v-card-title>
        <v-card-text class="text-center">{{ errorDialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeDialog">{{ $t('generic.lang_next') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapGetters, mapState} from "vuex";
import CashoutNumpad from "@/components/pos/cashout/CashoutNumpad";
import KeyboardMixIns from "../../mixins/KeyboardMixIns";

export default {
  name: "StartShiftCounting",
  components: {CashoutNumpad},
  mixins: [KeyboardMixIns],
  props: {
  },
  data() {
    return {
      dialog: false,
      earnings: 0,
      lastFocus: null,
      name: "",
      preFillName: "",
      countedStartCash: 0.00,
      countedStartCashOrigin: 0.00,
      usingCounting: false,
      loading: false,
      loadingActiveShift: false,
      showShiftErrorDialog: false,
      loadingCashbookValue: false,
      errorDialogMessage: "",
      cashbookEntryUUID: "",
      currency: {
        euro: {
          muenzen: [
            {
              value: 0.01,
              image: require("@/assets/images/currency/euro/1cents.png"),
              inValue: "",
            },
            {
              value: 0.02,
              image: require("@/assets/images/currency/euro/2cents.png"),
              inValue: "",
            },
            {
              value: 0.05,
              image: require("@/assets/images/currency/euro/5cents.png"),
              inValue: "",
            },
            {
              value: 0.1,
              image: require("@/assets/images/currency/euro/10cents.png"),
              inValue: "",
            },
            {
              value: 0.2,
              image: require("@/assets/images/currency/euro/20cents.png"),
              inValue: "",
            },
            {
              value: 0.5,
              image: require("@/assets/images/currency/euro/50cents.png"),
              inValue: "",
            },
            {
              value: 1,
              image: require("@/assets/images/currency/euro/1.png"),
              inValue: "",
            },
            {
              value: 2,
              image: require("@/assets/images/currency/euro/2.png"),
              inValue: "",
            },
          ],
          scheine: [
            {
              value: 5,
              image: require("@/assets/images/currency/euro/5.png"),
              inValue: "",
            },
            {
              value: 10,
              image: require("@/assets/images/currency/euro/10.png"),
              inValue: "",
            },
            {
              value: 20,
              image: require("@/assets/images/currency/euro/20.png"),
              inValue: "",
            },
            {
              value: 50,
              image: require("@/assets/images/currency/euro/50.png"),
              inValue: "",
            },
            {
              value: 100,
              image: require("@/assets/images/currency/euro/100.png"),
              inValue: "",
            }
          ]
        },
        mad: {
          muenzen: [
            {
              value: 0.5,
              image: require("@/assets/images/currency/dirham/0dot5.png"),
              inValue: "",
            },
            {
              value: 1,
              image: require("@/assets/images/currency/dirham/1.png"),
              inValue: "",
            },
            {
              value: 2,
              image: require("@/assets/images/currency/dirham/2.png"),
              inValue: "",
            },
            {
              value: 5,
              image: require("@/assets/images/currency/dirham/5.png"),
              inValue: "",
            },
            {
              value: 10,
              image: require("@/assets/images/currency/dirham/10.png"),
              inValue: "",
            },
          ],
          scheine: [
            {
              value: 20,
              image: require("@/assets/images/currency/dirham/20.png"),
              inValue: "",
            },
            {
              value: 50,
              image: require("@/assets/images/currency/dirham/50.png"),
              inValue: "",
            },
            {
              value: 100,
              image: require("@/assets/images/currency/dirham/100.png"),
              inValue: "",
            },
            {
              value: 200,
              image: require("@/assets/images/currency/dirham/200.png"),
              inValue: "",
            }
          ]
        }
      },
      hideSystemCountedCash: true,
      countingMandatory: true,
    }
  },
  watch: {
    /*   countedStartCash(val) {
         if (Number(val) <= 0) this.countedStartCash = -1 * this.countedStartCash;
       },*/
    dialog(val) {
      if (val) {
        this.getCashFromCashBook();
        this.loadDailyReportSettings();
        //SET DATE ON SHIFT NAME
        let date = new Date();

        let day = date.getDate();
        if (day < 10) {
          day = "0" + day;
        }

        let month = date.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }

        this.name = day + "." + month + "." + date.getFullYear();
        this.preFillName = this.name;
      } else {
        this.loading = false;
        this.hideSystemCountedCash = true;
        this.countingMandatory = true;
        this.usingCounting = true;
        
      }
    },
    usingCounting(val) {
      if (val) {

      } else {
        this.lastFocus = null;
      }
    }
  },
  computed: {
    ...mapState([
      'api',
      'user',
    ]),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient'
    }),
    currencyIMG(){
      return this.$store.getters['settings/getSettingValue']('geoloc_systemcountry') === "ma"?"mad":"euro";
    },
    total() {
      let somme = 0;
      this.currency[this.currencyIMG].muenzen.forEach(coin => {
        somme += coin.value * Number(coin.inValue);
      });
      this.currency[this.currencyIMG].scheine.forEach(paper => {
        somme += paper.value * Number(paper.inValue);
      });
      return somme;
    },
    overrideStartCash() {
      return Number(this.$store.getters['settings/getSettingValue']('override_start_shift_start_cash')) > 0;
    },
    overrideStartCashAmount() {
      return Number(this.$store.getters['settings/getSettingValue']('override_start_shift_start_cash_amount')) || 0;
    },
    validCountingInput(){
      if(!this.countingMandatory)
        return true;
      // return this.total > 0;
      const countedCoin = this.currency[this.currencyIMG].muenzen.some(coin => {
        return coin.inValue || Number(coin.inValue)
      });
      const countedPaper = this.currency[this.currencyIMG].scheine.some(paper => {
        return paper.inValue || Number(paper.inValue)
      });
      return countedCoin || countedPaper;
    }
  },
  methods: {
    loadDailyReportSettings() {
      this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.SETTINGS.GET).then((res) => {
        if (res.data.success) {
          this.hideSystemCountedCash = res.data.settings.hideSystemCountedCash;
          this.countingMandatory = res.data.settings.countingMandatory;
          this.usingCounting = res.data.settings.countingMandatory;
        }
        this.loading = false;
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      })
    },
    checkNegativity(e) {
      if (e.keyCode === 54 || e.keyCode === 109)
        this.countedStartCash = -1 * this.countedStartCash;
      if (Number(this.countedStartCash) <= 0) this.countedStartCash = -1 * this.countedStartCash;
    },
    update(value = null) {
      if (value !== null)
        if (this.lastFocus !== null) {
          this.lastFocus.inValue += '' + value;
        } else {
          this.countedStartCash = Number(this.countedStartCash) * 10;
          this.countedStartCash += Number(value) / 100;
        }

      if (this.usingCounting) {
        this.countedStartCash = this.total;
      }

    },
    remember(event, currency) {
      this.lastFocus = null;
      if (currency !== null) {
        this.lastFocus = currency;
      }
    },
    closeDialog() {
      this.dialog=false;
    },
    getCashFromCashBook() {
      this.loadingCashbookValue = true;

      this.axios.post(ENDPOINTS.POS.CASHBOOKVALUE, {
        cashierID: this.api.auth.cashierID
      }).then((res) => {
        if (res.data.success) {
          if(this.overrideStartCash){
            this.countedStartCash = this.overrideStartCashAmount;
            this.countedStartCashOrigin = this.overrideStartCashAmount;
          }else {
            this.countedStartCash = res.data.value;
            this.countedStartCashOrigin = res.data.value;
          }
        }

        this.loadingCashbookValue = false;
      }).catch(() => {
        this.loadingCashbookValue = false;
      });
    },
    checkShiftName() {
      if (this.name.length > 0) {
        if (this.name == this.preFillName) {
          this.name = "";
        }
      } else {
        this.name = this.preFillName;
      }
    },
    createShift() {
      this.loading = true;

      let countings = [];
      countings = this.currency[this.currencyIMG].muenzen.map(el => {
        return {
          value: el.value + '',
          qty: parseInt(Number(el.inValue))
        }
      }).concat(this.currency[this.currencyIMG].scheine.map(el => {
        return {
          value: el.value + '',
          qty: parseInt(Number(el.inValue)),
        }
      }))


      this.axios.post(ENDPOINTS.POS.ACTIVESHIFT.CREATE, {
        cashierID: this.api.auth.cashierID,
        name: this.name,
        countedStartCash: this.countedStartCash,
        startCashCountingProtocol: (this.usingCounting) ? countings : null,
      }).then((res) => {
        if (res.data.success) {
          this.cashbookEntryUUID = res.data.entryUUID;

          this.$store.dispatch("pos/updateActiveShift", {
            uuid: res.data.activeShiftUUID
          }).then(() => {
            this.checkTSE();
          });
        } else {
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false;
      })
    },
    checkTSE() {
      //CHECK IF TSE CLIENT IS SETUP
      if (this.fiscalClient === null) {
        this.closeDialog();
        return;
      }

      if (this.fiscalClient.device.type === null) {
        this.fiscalClient.device.type = "epsonTSE";
      }
      //FISKALY CLOUD TSE
      if (this.fiscalClient.device.type === "fiskaly") {
        this.closeDialog();
        return;
      }

      let tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

      if (!tseDevice.tseReady) {
        this.errorDialogMessage = this.$t('generic.lang_tseIsNotOperational');
        return;
      }

      tseDevice.GFE_StartTransaction(this.fiscalClient.clientID);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "StartTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            this.finishTransaction(payload.result.output, tseDevice);
          } else {
            this.errorDialogMessage = this.$t('generic.lang_couldNotCompleteSigning') + ": " + payload.result.result;
          }
        }
      });
    },
    finishTransaction(transactionPayload, tseDevice) {
      let changedValue = (this.countedStartCash - this.countedStartCashOrigin).toFixed(2);

      //CREATE FAKE ITEM TO USE EXISTING FUNCTION
      let items = [{
        isVoid: false,
        sellPrice: changedValue,
        taxValue: 0,
        amount: 1,
      }];

      tseDevice.finishInvoiceTransaction(this.fiscalClient.clientID, transactionPayload, items, 1, []);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "FinishTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            this.saveFiscalData(transactionPayload, payload.result.output, tseDevice);
          } else {
            this.errorDialogMessage = this.$t('generic.lang_couldNotCompleteSigning') + ": " + payload.result.result;
          }
        }
      });
    },
    saveFiscalData(startTransactionResultOutput = null, finishTransactionResultOutput = null, tseDevice) {
      //CHECK IF ENTRY WAS CREATED
      if (this.cashbookEntryUUID === null) {
        this.closeDialog();
        return;
      }

      //SAVE FISCAL DATA INTO DATABASE
      if (startTransactionResultOutput !== null && finishTransactionResultOutput !== null) {
        let payload = {
          fiscalDevice: {
            id: this.fiscalClient.device.id,
            deviceID: this.fiscalClient.device.deviceID
          },
          fiscalClient: {
            id: this.fiscalClient.id,
            clientID: this.fiscalClient.clientID
          },
          tse: {
            serialNumber: tseDevice.storageInfo.tseInformation.serialNumber,
          },
          transaction: {
            processType: "Kassenbeleg-V1",
            startTime: startTransactionResultOutput.logTime,
            stopTime: finishTransactionResultOutput.logTime,
            transactionNumber: startTransactionResultOutput.transactionNumber,
            signatureCounter: finishTransactionResultOutput.signatureCounter,
            signature: finishTransactionResultOutput.signature
          }
        };

        //SAVE FISCAL DATA INTO DATABASE
        this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.UPDATETSEDATA, {
          entryUUID: this.cashbookEntryUUID,
          tsePayload: payload
        }).then(() => {
          this.closeDialog();
        }).catch(() => {
          this.closeDialog();
        });
      }
    },
    del() {

      if (this.usingCounting) {

        this.currency[this.currencyIMG].muenzen.forEach(coin => {
          coin.inValue = '';
        });
        this.currency[this.currencyIMG].scheine.forEach(paper => {
          paper.inValue = '';
        });

        this.countedStartCash = this.total;

      }

      if (this.lastFocus === null) {
        this.countedStartCash = '';
      }
    },
    getActiveShift() {
      this.loadingActiveShift = true;

      this.axios.post(ENDPOINTS.POS.ACTIVESHIFT.GET, {
        cashierID: this.api.auth.cashierID
      }).then((res) => {
        if (res.data.success) {
          //ATTACH ACTIVE SHIFT
          this.$store.dispatch("pos/updateActiveShift", res.data.activeShift);
        } else {
          if (res.data.code === "not_found") {
            //SHOW SHIFT CREATE DIALOG
            this.dialog = true;

            return;
          }

          this.showShiftErrorDialog = true;
        }
      }).catch((err) => {
        this.showShiftErrorDialog = true;
      }).finally(() => {
        this.loadingActiveShift = false;
      });
    },
    cancelDialog() {
      this.dialog = false;

      //REDIRECT TO DASHBOARD BECAUSE WE NEED TO CREATE A SHIFT
      this.$router.replace({
        name: 'dashboard'
      });
    },
    refreshPage() {
      window.location.reload();
    },
  },
  mounted() {
    this.getActiveShift();
  },
}
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
